import {debounce} from '@github/mini-throttle'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {onInput} from '../onfocus'
import {parseHTML} from '@github-ui/parse-html'

interface EditorResponse {
  normalized_ref?: string
  message_html?: string
}

let previousRequest: AbortController | null = null
const targetBranchInputElement: HTMLInputElement | null = null
async function updateTargetBranch() {
  const proposedBranchInputElement = document.querySelector<HTMLInputElement>('.js-quick-pull-new-branch-name')!
  const proposedRef = proposedBranchInputElement.value
  const generatedBranchName = proposedBranchInputElement.getAttribute('data-generated-branch')

  const normalizationElement = document.querySelector<HTMLElement>('.js-quick-pull-normalization-info')!
  const data = new FormData()
  data.append('ref', proposedRef)

  const checkUrl = proposedBranchInputElement.getAttribute('data-check-url')!
  const token = proposedBranchInputElement.parentElement!.querySelector<HTMLInputElement>('.js-data-check-url-csrf')!

  previousRequest?.abort()
  const {signal} = (previousRequest = new AbortController())
  try {
    const response = await fetch(checkUrl, {
      mode: 'same-origin',
      method: 'POST',
      body: data,
      headers: {Accept: 'application/json', 'Scoped-CSRF-Token': token.value, 'X-Requested-With': 'XMLHttpRequest'},
    })
    if (response.ok) {
      const editorResponse = (await response.json()) as EditorResponse
      if (proposedRef !== proposedBranchInputElement.value) {
        return
      }
      const normalizedRef = editorResponse['normalized_ref']
      normalizationElement.innerHTML = editorResponse['message_html'] == null ? '' : editorResponse['message_html']
      if (!normalizedRef) {
        const code = normalizationElement.querySelector<HTMLElement>('code')!
        code.textContent = generatedBranchName
      }

      if (targetBranchInputElement) {
        targetBranchInputElement.value = normalizedRef || ''
      }
    }
  } catch {
    // ignore
  }
  if (signal.aborted) return
  if (proposedRef !== proposedBranchInputElement.value) {
    return
  }
  if (targetBranchInputElement) {
    targetBranchInputElement.value = proposedRef
  }
}
const debouncedUpdateTargetBranch = debounce(updateTargetBranch, 200)

observe('.js-template-form .js-issue-labels', {
  add(labelElementsContainer) {
    const labelsContainer = labelElementsContainer.closest('.js-issue-template-labels-container')
    if (!labelsContainer) {
      return
    }

    const labelsInput = labelsContainer.querySelector<HTMLInputElement>('.js-issue-template-labels')!
    labelsInput.value = ''

    for (const labelElement of labelElementsContainer.children) {
      const label = labelElement.getAttribute('data-name')

      if (label && !labelsInput.value.includes(label)) {
        if (labelsInput.value === '') {
          labelsInput.value = label
        } else {
          labelsInput.value = `${labelsInput.value}, ${label}`
        }
      }
    }
  },
})

observe('.js-template-form .js-issue-assignees', {
  add(assigneeElementsContainer) {
    const assigneesContainer = assigneeElementsContainer.closest('.js-issue-template-assignees-container')!
    const assigneesInput = assigneesContainer.querySelector<HTMLInputElement>('.js-issue-template-assignees')!
    assigneesInput.value = ''

    for (const assigneeElement of assigneeElementsContainer.children) {
      const assigneeSpan = assigneeElement.querySelector('span')

      if (assigneeSpan) {
        const assignee = assigneeSpan.getAttribute('data-assignee-name')
        if (assignee && !assigneesInput.value.includes(assignee)) {
          if (assigneesInput.value === '') {
            assigneesInput.value = assignee
          } else {
            assigneesInput.value = `${assigneesInput.value}, ${assignee}`
          }
        }
      }
    }
  },
})

on('change', '.js-quick-pull-choice-option', function (e) {
  if (!isUsingTemplateEditor()) {
    return
  }

  const input = e.currentTarget as HTMLInputElement
  const branchNameInput = document.querySelector<HTMLElement>('.js-quick-pull-new-branch-name')!
  branchNameInput.toggleAttribute('required', input.value === 'quick-pull')
})

onInput('.js-quick-pull-new-branch-name', function (event) {
  if (!isUsingTemplateEditor()) {
    return
  }

  const el = event.target as HTMLInputElement
  const proposedRef = el.value
  const quickPullTargetBranch = document.querySelector<HTMLInputElement>('.js-quick-pull-target-branch')!
  quickPullTargetBranch.value = proposedRef
  if (proposedRef.length) {
    debouncedUpdateTargetBranch()
  }
})

onInput('.js-synced-template-input', function (event) {
  const input = event.target as HTMLInputElement
  const syncName = input.getAttribute('data-sync')
  if (!syncName) {
    return
  }

  const container = input.closest<HTMLElement>('.js-sync-container')!
  const syncedElements = container.querySelectorAll(`[data-sync-with="${syncName}"]`)
  const value = input.value

  if (value.trim() === '') {
    for (const el of syncedElements) {
      const syncBlankValue = el.getAttribute('data-sync-blank')
      if (syncBlankValue) {
        el.textContent = ''

        const spanElement = document.createElement('span')
        spanElement.className = 'color-fg-muted'
        spanElement.textContent = syncBlankValue

        el.appendChild(spanElement)
      }
    }

    return
  }

  for (const el of syncedElements) {
    el.textContent = input.value
  }

  if (syncName === 'name') {
    const filenameHiddenField = container.querySelector<HTMLInputElement>('.js-sync-filename')!
    filenameHiddenField.value = `${value.replace(
      /[^\p{Letter}\p{Mark}\p{Number}\p{Connector_Punctuation}\p{Emoji}]/gu,
      '-',
    )}.md`.toLowerCase()
  }
})

function isUsingTemplateEditor(): boolean {
  return document.querySelectorAll('.js-template-editor').length > 0
}

function collapseAllPreviews() {
  // Close all other previews
  const previews = document.querySelectorAll('.js-template-preview')
  for (const preview of previews) {
    preview.classList.remove('expand-preview')
  }
}

function templateEditorState(): 'error' | 'loading' | 'ok' {
  const loadingLength = document.querySelectorAll('.js-template-form.is-loading').length
  if (loadingLength > 0) {
    return 'loading'
  }

  const erroredLength = document.querySelectorAll('.js-template-form.is-errored').length
  if (erroredLength > 0) {
    return 'error'
  }

  return 'ok'
}

function syncCommitFormWithTemplateValidity() {
  const commitForm = document.querySelector<HTMLFormElement>('.js-commit-templates-form')!
  const submitButton = commitForm.querySelector<HTMLButtonElement>('.js-blob-submit')!
  const commitErrorMessage = document.querySelector<HTMLElement>('.js-template-commit-form-error-message')!
  const loadingMessage = document.querySelector<HTMLElement>('.js-template-commit-form-loading-message')!

  switch (templateEditorState()) {
    case 'loading':
      /* eslint-disable-next-line github/no-d-none */
      commitErrorMessage.classList.add('d-none')
      /* eslint-disable-next-line github/no-d-none */
      loadingMessage.classList.remove('d-none')
      commitForm.setAttribute('disabled', 'disabled')
      submitButton.setAttribute('disabled', 'disabled')
      break
    case 'error':
      /* eslint-disable-next-line github/no-d-none */
      commitErrorMessage.classList.remove('d-none')
      /* eslint-disable-next-line github/no-d-none */
      loadingMessage.classList.add('d-none')
      commitForm.setAttribute('disabled', 'disabled')
      submitButton.setAttribute('disabled', 'disabled')
      break
    default:
      /* eslint-disable-next-line github/no-d-none */
      commitErrorMessage.classList.add('d-none')
      /* eslint-disable-next-line github/no-d-none */
      loadingMessage.classList.add('d-none')
      commitForm.removeAttribute('disabled')
      submitButton.removeAttribute('disabled')
  }
}

async function fetchPreview(form: HTMLFormElement) {
  form.classList.add('is-loading')
  syncCommitFormWithTemplateValidity()

  const currentFilename = form.querySelector<HTMLInputElement>('[name="filename"]')!.value
  const oldFilenameElement = form.querySelector<HTMLInputElement>('[name="old_filename"]')
  const oldFilename = oldFilenameElement!.value
  const templates: {[key: string]: {[key: string]: string}} = {}
  for (const templateForm of document.querySelectorAll<HTMLFormElement>('.js-template-form')) {
    const filename = templateForm.querySelector<HTMLInputElement>('[name="filename"]')!.value
    templates[filename] = {}
    const formData = new FormData(templateForm)
    for (const [key, value] of formData.entries()) {
      templates[filename][key] = value.toString()
    }
  }

  const formData = new FormData(form)
  formData.append('current', currentFilename)
  formData.append('templates', JSON.stringify(templates))
  const response = await fetch(form.action, {
    method: 'POST',
    body: formData,
    headers: {Accept: 'application/json', 'X-Requested-With': 'XMLHttpRequest'},
  })
  if (!response.ok) return
  const json = (await response.json()) as {markdown: string; filename: string; html: string}
  const replacement = parseHTML(document, json.html)
  form.closest<HTMLElement>('.js-template-preview')!.replaceWith(replacement)

  const hiddenFieldsContainer = document.querySelector<HTMLElement>('.js-hidden-template-fields')!
  const hiddenField = hiddenFieldsContainer.querySelector(`[data-filename="${oldFilename}"]`)
  if (hiddenField instanceof HTMLInputElement) {
    hiddenField.value = json.markdown
    hiddenField.setAttribute('data-filename', json.filename)
    hiddenField.name = `templates[][${json.filename}]`
  } else {
    const newHiddenField = document.createElement('input')
    newHiddenField.type = 'hidden'
    newHiddenField.name = `templates[][${json.filename}]`
    newHiddenField.setAttribute('data-filename', json.filename)
    newHiddenField.value = json.markdown
    hiddenFieldsContainer.append(newHiddenField)
  }

  form.classList.remove('is-loading')
  syncCommitFormWithTemplateValidity()
}

function validateAllTemplateForms() {
  for (const form of document.querySelectorAll<HTMLFormElement>('.js-template-form')) {
    fetchPreview(form)
  }
}

on('submit', '.js-template-form', function (e) {
  e.preventDefault()
  const form = e.currentTarget as HTMLFormElement
  fetchPreview(form)
})

on('click', '.js-toggle-template-commit', function () {
  const pane = document.querySelector<HTMLElement>('.js-template-commit-pane')!
  /* eslint-disable-next-line github/no-d-none */
  pane.classList.toggle('d-none')

  /* eslint-disable-next-line github/no-d-none */
  if (!pane.classList.contains('d-none')) {
    validateAllTemplateForms()
  }
})

on('submit', '.js-commit-templates-form', function (e) {
  if (templateEditorState() !== 'ok') {
    e.preventDefault()
  }

  syncCommitFormWithTemplateValidity()
})

on('click', '.js-refresh-template-content', async function (e) {
  const el = e.currentTarget
  const container = el.closest<HTMLElement>('.js-template-form')!
  const preview = container.querySelector<HTMLElement>('.js-template-content-preview')!
  // eslint-disable-next-line github/unescaped-html-literal
  preview.innerHTML = `<span class="color-fg-muted">Loading preview...</span>`

  const text = container.querySelector<HTMLTextAreaElement>('.js-template-content-textarea')!.value
  const url = el.getAttribute('data-markdown-preview-url')
  if (!url) return

  const token = el.parentElement!.querySelector<HTMLInputElement>('.js-data-markdown-preview-url-csrf')!
  const body = new FormData()
  body.append('markdown', text)

  const response = await fetch(url, {
    mode: 'same-origin',
    method: 'POST',
    body,
    headers: {'Scoped-CSRF-Token': token.value, 'X-Requested-With': 'XMLHttpRequest'},
  })
  if (!response.ok) return
  const html = await response.text()
  preview.innerHTML = html
})

on('click', '.js-custom-template-toggle', event => {
  const toggle = event.target

  // Expand current preview
  const template = (toggle as Element).closest<HTMLElement>('.js-template-preview')!

  // Once we make sure all the other previews are collapsed, should we open this one?
  const shouldShow = !template.classList.contains('expand-preview')

  collapseAllPreviews()

  if (shouldShow) {
    template.classList.add('expand-preview')
    template.scrollIntoView({behavior: 'smooth', block: 'start'})
  } else {
    const form = template.querySelector<HTMLFormElement>('.js-template-form')!
    fetchPreview(form)
  }
})

on('click', '.js-remove-template-button', function (e) {
  const template = (e.target as Element).closest<HTMLElement>('.js-template-preview')!
  const templateFilename = template.getAttribute('data-filename')!
  const hiddenFieldsContainer = document.querySelector<HTMLElement>('.js-hidden-template-fields')!
  const proposeChangesButton = document.querySelector<HTMLButtonElement>('.js-toggle-template-commit')!
  const hiddenField = hiddenFieldsContainer.querySelector(`[data-filename="${templateFilename}"]`)
  if (hiddenField) {
    hiddenField.remove()
  }
  proposeChangesButton.disabled = false

  template.remove()
  validateAllTemplateForms()
})

on('click', '.js-edit-custom-field-header', function (e) {
  const template = (e.target as Element).closest<HTMLElement>('.js-custom-field-header')!
  template.classList.toggle('section-focus')

  const proposeChangesButton = document.querySelector<HTMLButtonElement>('.js-toggle-template-commit')!
  proposeChangesButton.disabled = false
})

on(
  'details-menu-selected',
  '.js-add-template',
  function (event) {
    const container = document.querySelector<HTMLElement>('.js-templates-container')!
    const proposeChangesButton = document.querySelector<HTMLButtonElement>('.js-toggle-template-commit')!
    const templateId = event.detail.relatedTarget.value
    if (!templateId) return

    proposeChangesButton.disabled = false
    const templateEl = document.getElementById(templateId) as HTMLTemplateElement
    container.append(templateEl.content.cloneNode(true))
  },
  {capture: true},
)
